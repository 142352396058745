/* eslint-disable max-len */
export default {
  en: {
    name: 'CoinFalcon - Cryptocurrency Exchange',
    main: {
      title: 'CoinFalcon – Buy and Sell Bitcoin, Ethereum | Cryptocurrency Exchange',
      desc: 'CoinFalcon is the trusted cryptocurrency exchange that allows you to buy, sell, and track the price of Bitcoin, Ethereum, XRP, Litecoin and Bitcoin Cash, among other cryptocurrencies, within minutes. Trade with other digital assets or use your credit card!',
    },
    coinDetails: {
      title: '{ name } Price Chart ({ code }), market cap | #meta.name#',
      desc: 'Never miss a price swing of { name } and monitor the market to make informed buy or sell decisions. Trade { code } on the go and stay up to date on the value of your investment. Start buying and selling { name } ({ code }) and other cryptocurrency today.',
    },
    howToBuy: {
      title: 'How to buy { name } | #meta.name#',
      desc: 'Buy { name } with credit card, debit card, netbanking and other payment options. CoinFalcon is Europe\'s trusted { name } trader platform. Get { code } price in USD and Euro and buy { name } in just few minutes.',
    },
    howToBuyEth: {
      title: '#meta.howToBuy.title#',
      desc: 'Buy Ethereum from the trusted cryptocurrecy exchange platform in Europe. Get Eth price in USD and Euro and buy Ethereum in just few minutes.',
    },
    howToBuyIota: {
      title: '#meta.howToBuy.title#',
      desc: 'Purchase IOTA coin with credit card or with any of our other payment options. Buying IOTA on CoinFalcon is easy, fast and secure. Signup for free & earn €10 on our referral program.',
    },
    prices: {
      title: 'Cryptocurrency Prices | #meta.name#',
      desc: 'Never miss a price swing of your favourite cryptocurrencies and monitor the market to make informed buy or sell decisions. Trade on the go and stay up to date on the value of your investment. Start buying and selling Bitcoin Cash, Ethereum, Cardano and other cryptocurrency today.',
    },
    referral: {
      title: 'Referral Program | #meta.name#',
    },
    about: {
      title: 'About us | #meta.name#',
    },
    aml: {
      title: 'AML Policy | #meta.name#',
    },
    contact: {
      title: 'Contact us | #meta.name#',
    },
    cookie: {
      title: 'Cookie policy | #meta.name#',
    },
    fees: {
      title: 'Fees | #meta.name#',
    },
    notFound: {
      title: 'Page not found | #meta.name#',
    },
    privacy: {
      title: 'Privacy policy | #meta.name#',
    },
    refund: {
      title: 'Refund and Return policy | #meta.name#',
    },
    terms: {
      title: 'Terms of Use | #meta.name#',
    },
  },
  de: {
    name: 'CoinFalcon - Handelsplatform für Kryptowährungen',
    main: {
      title: 'CoinFalcon – Kaufe und verkaufe Bitcoin, Ethereum | Kryptowährungen Exchange',
      desc: 'CoinFalcon ist eine Kryptowährungen Handelsplatform, auf welcher du kaufen und verkaufen kannst, den Preis von Bitcoin, Ethereum, Iota, Litecoin und Bitcoin Cash verfolgen kannst. Handel mit Euro per Banküberweisung oder mit Kreditkarte!',
    },
    coinDetails: {
      title: '{ name } Preis chart ({ code }), Marktkapitalisierung | #meta.name#',
      desc: 'Verpasse niemals eine Chance für die Investition in { name }. Handel immer und überall { code } und bleibe stets auf dem laufenden über deine Investitionen. Kaufe und verkaufe { name } ({ code }) und andere Kryptowährungen.',
    },
    howToBuy: {
      title: 'Wie kann ich { name } | #meta.name# kaufen?  ',
      desc: 'Kaufe { name } mit Kreditkarte, Debitkarte, online Überweisung oder anderen Zahlungsmitteln. CoinFalcon ist Europas vertraute { name } Handelsplatform. Erhalte die neusten Updates über { code } und kaufe { name } in wenigen minuten.',
    },
    howToBuyEth: {
      title: '#meta.howToBuy.title#',
      desc: 'Kaufe Ethereum von einer vertrauten Kryptowährung handelsplatform in Europa. Kaufe Ethereum in nur wenigen minuten mit Kreditkarte.',
    },
    howToBuyIota: {
      title: '#meta.howToBuy.title#',
      desc: 'Kaufe IOTA mit Kreditkarte oder mit anderen Zahlungsmethoden. IOTA auf CoinFalcon zu kaufen ist einfach, schnell und sicher. Melde dich jetzt and und bekomme 10€ mit unserem Freundewerben programm.',
    },
    prices: {
      title: 'Kryptowährungen Preise | #meta.name#',
      desc: 'Bleibe immer auf dem Laufenden und verfolge deinen beliebtesten Krywptowährungen. Handel von überall und behalte den Überblick über die aktuellen Preise und deine Investitionen. Kaufe und verkaufe Bitcoin, Ethereum, IOTA und andere Kryptowährungen.',
    },
    referral: {
      title: 'Freunde werben | #meta.name#',
    },
    about: {
      title: 'Über CoinFalcon | #meta.name#',
    },
    aml: {
      title: 'AML Policy | #meta.name#',
    },
    contact: {
      title: 'Contact us | #meta.name#',
    },
    cookie: {
      title: 'Cookie policy | #meta.name#',
    },
    fees: {
      title: 'Gebühren | #meta.name#',
    },
    notFound: {
      title: 'Seite nicht gefunden | #meta.name#',
    },
    privacy: {
      title: 'Privacy policy | #meta.name#',
    },
    refund: {
      title: 'Refund and Return policy | #meta.name#',
    },
    terms: {
      title: 'Terms of Use | #meta.name#',
    },
  },
  pl: {
    name: 'CoinFalcon - giełda kryptowalut',
    main: {
      title: 'CoinFalcon – Kupuj i Sprzedawaj Bitcoin, Ethereum | Giełda kryptowalut',
      desc: 'CoinFalcon jest zaufaną giełdą kryptowalut, która umożliwia kupowanie, sprzedaż oraz śledzenie ceny Bitcoin, Ethereum, XRP, Litecoin i Bitcoin Cash, oraz innych kryptowalut. Handluj używając innych walut cyfrowych lub karty kredytowej!',
    },
    coinDetails: {
      title: 'Wykres ceny { name } ({ code }), kapitalizacja | CoinFalcon',
      desc: 'Nie przegap wahań ceny { name } i obserwuj rynek, aby podejmować właściwe decyzje kupna lub sprzedaży. Handluj { code } od ręki i bądź na bieżąco z wartością swojej inwestycji. Zacznij kupować oraz sprzedawać { name } ({ code }) i inne kryptowaluty już dziś.',
    },
    howToBuy: {
      title: 'Jak kupować { name } | #meta.name#',
      desc: 'Kupuj { name } kartą kredytową, debetową lub innymi formami płatności. CoinFalcon jest zaufaną europejską platformą handlu { name }. Sprawdź cenę { code } w USD i Euro oraz kup { name } w zaledwie kilka minut.',
    },
    howToBuyEth: {
      title: '#meta.howToBuy.title#',
      desc: 'Kup Ethereum na zaufanej giełdzie kryptowalut z Europy. Sprawdź cenę ETH w USD i Euro oraz kup Ethereum w zaledwie kilka minut.',
    },
    howToBuyIota: {
      title: '#meta.howToBuy.title#',
      desc: 'Kup IOTA za pomocą karty kredytowej albo którejkolwiek z naszych metod płatności. Zakup IOTA na CoinFalcon jest prosty, szybki i bezpieczny. Zarejestruj się za darmo i otrzymaj €10 w naszym programie poleceń.',
    },
    prices: {
      title: 'Ceny kryptowalut | #meta.name#',
      desc: 'Nie przegap wahań cen Twoich ulubionych kryptowalut i obserwuj rynek, aby podejmować właściwe decyzje kupna lub sprzedaży. Handluj od ręki i bądź na bieżąco z wartością swojej inwestycji. Zacznij kupować oraz sprzedawać Bitcoin Cash, Ethereum, Cardano i inne kryptowaluty już dziś.',
    },
    referral: {
      title: 'Program poleceń | #meta.name#',
    },
    about: {
      title: 'O nas | #meta.name#',
    },
    aml: {
      title: 'Zasady AML | #meta.name#',
    },
    contact: {
      title: 'Kontakt | #meta.name#',
    },
    cookie: {
      title: 'Zasady przechowywania plików cookie | #meta.name#',
    },
    fees: {
      title: 'Prowizje i opłaty | #meta.name#',
    },
    notFound: {
      title: 'Strona nie została znaleziona | #meta.name#',
    },
    privacy: {
      title: 'Polityka prywatności | #meta.name#',
    },
    refund: {
      title: 'Polityka zwrotów | #meta.name#',
    },
    terms: {
      title: 'Warunki korzystania | #meta.name#',
    },
  },
};
